import React, { useEffect, useState } from "react";
import H2 from "../../../../components/typography/H2/H2";
import * as classes from "./Hero.module.scss";
import cm from "../../../../utils/cm";

type Props = {
  header: React.ReactNode;
  tagline: React.ReactNode;
};

export default function Hero({ header, tagline }: Props) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <>
      <article
        className={cm(classes.hero, mounted ? classes.shown : classes.hidden)}
      >
        {mounted && (
          <>
            <div className={classes.animator}></div>
            <h1>
              <div className={classes.textAnimator}>{header}</div>
            </h1>
            <H2>
              <div className={classes.textAnimator}>{tagline}</div>
            </H2>
          </>
        )}
      </article>
    </>
  );
}
