import React from "react";
import Grid, { GridColumn } from "../../../../components/layout/Grid/Grid";
import content from "../../../../content";
import * as classes from "./Logos.module.scss";

type Props = {};

export default function Logos(props: Props) {
  return (
    <>
      <div className={classes.logos}>
        <Grid>
          <GridColumn span={[1, 1]}>
            <div className={classes.gridItem}>
              <p>{content.frontLogosLegend}</p>
            </div>{" "}
          </GridColumn>
          <GridColumn span={[2, 5]}>
            <div className={classes.gridItem}>
              <picture>
                <source
                  media="(min-width:900px)"
                  srcSet="/logo-row-desktop.png"
                />
                <source
                  media="(max-width:900px)"
                  srcSet="/logo-row-mobile.png"
                />
                <img
                  alt="Loggotyper av samarbetspartners"
                  src="/logo-row-desktop.png"
                />
              </picture>
            </div>
          </GridColumn>
        </Grid>
      </div>
    </>
  );
}
