import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

function useOnScrollIn(
  duration: number = 0,
  threshold: number = 0.15
): [(node?: Element | null | undefined) => void, boolean] {
  const [ref, inViewCur] = useInView({ threshold: threshold });
  const [hasBeenInView, setHasBeenInView] = useState(inViewCur);

  useEffect(() => {
    if (!hasBeenInView && inViewCur) {
      setTimeout(() => setHasBeenInView(true), duration);
    }
  }, [inViewCur]);

  return [ref, hasBeenInView];
}

export default useOnScrollIn;
