// extracted by mini-css-extract-plugin
export var analysis = "Contact-module--analysis--0fcfc";
export var analysisItem = "Contact-module--analysisItem--95f66";
export var contact = "Contact-module--contact--41e5d";
export var contactMethod = "Contact-module--contactMethod--6585d";
export var contactMethodGridLast = "Contact-module--contactMethodGridLast--c76bd";
export var desktop = "Contact-module--desktop--baa50";
export var employee = "Contact-module--employee--f5e28";
export var header = "Contact-module--header--bfc13";
export var mobile = "Contact-module--mobile--d9843";
export var mobileAnalysisGrid = "Contact-module--mobileAnalysisGrid--0e5ea";
export var stretch = "Contact-module--stretch--9e20b";