import React from "react";
import Constrained from "../../components/layout/Constrained/Constrained";
import Layout from "../../components/layout/Layout/Layout";
import Page from "../../components/layout/Page/Page";
import content from "../../content";
import Contact from "./deps/Contact/Contact";
import Hero from "./deps/Hero/Hero";
import InfoBox from "./deps/InfoBox/InfoBox";
import Logos from "./deps/Logos/Logos";
import Welcome from "./deps/Welcome/Welcome";
import classes from "./Front.module.scss";
import { Helmet } from "react-helmet";
import Cases from "./deps/Cases/Cases";
import Quotes from "./deps/Quotes/Quotes";

type Props = {};

export default function Front(props: Props) {
  return (
    <>
      <Page title="Enter Technologies - Webbyrå i Stockholm">
        <Layout>
          <Constrained>
            <Hero
              tagline={content.frontHeroTagline}
              header={content.frontHeroHeader}
            />
          </Constrained>
          <Constrained>
            <Logos />
            <Welcome />
          </Constrained>
          <Constrained>
            <InfoBox />
          </Constrained>
          <Constrained>
            <Cases />
          </Constrained>
          <Constrained>
            <Quotes />
          </Constrained>
          <Constrained>
            <Contact />
          </Constrained>
        </Layout>
      </Page>
    </>
  );
}
