import React, { useEffect } from "react";
import Grid, { GridColumn } from "../../../../components/layout/Grid/Grid";
import H2 from "../../../../components/typography/H2/H2";
import UnderlineLink from "../../../../components/ui/UnderlineLink/UnderlineLink";
import content from "../../../../content";
import cm from "../../../../utils/cm";
import * as classes from "./Contact.module.scss";
import Text from "../../../../components/typography/Text/Text";

const {
  frontContactEmployees,
  frontContactBottomText,
  frontContactMethods,
  frontAnalysisBoxHeader,
  frontAnalysisParagraph,
  frontAnalysisContact,
} = content;

type Props = {};

export default function Contact(props: Props) {
  return (
    <>
      <div id="kontakt">
        <Grid>
          <GridColumn span={[1, 3]}>
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <H2 className={classes.header}>Kontakt</H2>
              <Grid
                style={{
                  minHeight: "calc(var(--sf) * (327px - 57px - 14px))",
                }}
              >
                <GridColumn span={[1, 3]}>
                  {frontContactMethods
                    .filter((_, i) => i % 2 === 0)
                    .map((content, i) => (
                      <ContactMethod key={i} content={content} />
                    ))}
                </GridColumn>
                <GridColumn
                  className={classes.contactMethodGridLast}
                  span={[3, 5]}
                >
                  {frontContactMethods
                    .filter((_, i) => (i + 1) % 2 === 0)
                    .map((content, i) => (
                      <ContactMethod key={i} content={content} />
                    ))}
                </GridColumn>
              </Grid>
            </div>
            <GridColumn
              className={cm(classes.mobile, classes.mobileAnalysisGrid)}
              span={[3, 5]}
            >
              <AnalysisBox />
            </GridColumn>
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <H2 className={classes.header}>Medarbetare</H2>
              <Grid>
                <GridColumn span={[1, 3]}>
                  {frontContactEmployees
                    .filter((_, i) => i % 2 === 0)
                    .map((employee, i) => (
                      <Employee key={i} {...employee} />
                    ))}
                </GridColumn>
                <GridColumn span={[3, 5]}>
                  {frontContactEmployees
                    .filter((_, i) => (i + 1) % 2 === 0)
                    .map((employee, i) => (
                      <Employee key={i} {...employee} />
                    ))}
                </GridColumn>
              </Grid>
              <div>{frontContactBottomText}</div>
            </div>
          </GridColumn>

          <GridColumn className={classes.desktop} span={[3, 5]}>
            <AnalysisBox />
          </GridColumn>
        </Grid>
      </div>
    </>
  );
}

function AnalysisBox() {
  return (
    <div
      className={classes.analysis}
      data-sal="slide-up"
      data-sal-delay="700"
      data-sal-duration="300"
      data-sal-easing="ease-out"
    >
      <div>
        <H2 style={{ color: "#fff" }}>{frontAnalysisBoxHeader}</H2>
        <Text>{frontAnalysisParagraph}</Text>
      </div>
      <div className={classes.stretch}></div>
      <Grid>
        <GridColumn className={classes.analysisItem} span={[1, 3]}>
          <Text>{frontAnalysisContact.left}</Text>
        </GridColumn>
        <GridColumn className={cm(classes.analysisItem)} span={[3, 5]}>
          <Text>{frontAnalysisContact.right}</Text>
        </GridColumn>
      </Grid>
    </div>
  );
}

function ContactMethod({ content }: { content: React.ReactNode }) {
  return <Text className={classes.contactMethod}>{content}</Text>;
}

function Employee({
  name,
  title,
  email,
}: {
  name: string;
  title: string;
  email: string;
}) {
  return (
    <>
      <div className={classes.employee}>
        <Text>{name}</Text>
        <Text>{title}</Text>
        <Text>
          <UnderlineLink href={`mailto:${email}`}>{email}</UnderlineLink>
        </Text>
      </div>
    </>
  );
}
