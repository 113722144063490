import React from "react";
import H2 from "../../../../components/typography/H2/H2";
import content from "../../../../content";
import * as classes from "./Cases.module.scss";
import Masonry from "react-masonry-css";
import useMedia from "use-media";
import { useInView } from "react-intersection-observer";
import useOnScrollIn from "../../../../hooks/useOnScrollIn";
import cm from "../../../../utils/cm";

type Props = {};

export default function Cases(props: Props) {
  const isWide = useMedia({ minWidth: 1000 });

  return (
    <div className={classes.cases}>
      <h1>Utvalda projekt</h1>

      <Masonry
        breakpointCols={isWide ? 2 : 1}
        className={classes.masonryGrid}
        columnClassName={classes.masonryGridGridColumn}
      >
        {content.cases.map((c, i) => (
          <Case c={c} key={i} />
        ))}
      </Masonry>
    </div>
  );
}

function Case({
  c,
}: {
  c: { title: React.ReactNode; description: React.ReactNode; image: string };
}) {
  const [ref, animateIn] = useOnScrollIn(100);

  return (
    <>
      <div ref={ref} className={cm(classes.Case, animateIn && classes.animate)}>
        <H2>{c.title}</H2>
        <p>{c.description}</p>
        <div className={classes.image}>
          <div
            className={classes.imageInner}
            style={{ backgroundImage: `url("${c.image}")` }}
          ></div>
        </div>
      </div>
    </>
  );
}
