import React, { useEffect, useRef } from "react";
import Grid, { GridColumn } from "../../../../components/layout/Grid/Grid";
import H2 from "../../../../components/typography/H2/H2";
import content from "../../../../content";
import * as classes from "./Welcome.module.scss";

type Props = {};

export default function Welcome(props: Props) {
  return (
    <>
      <div className={classes.welcome}>
        <Grid>
          <GridColumn
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            className={classes.gridItem}
            span={[1, 3]}
          >
            <H2>{content.frontWelcomeText}</H2>
          </GridColumn>
          <GridColumn
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            className={classes.gridItem}
            span={[3, 5]}
          >
            {" "}
            <H2>{content.frontWelcomeParagraph}</H2>
          </GridColumn>
        </Grid>
      </div>
    </>
  );
}
