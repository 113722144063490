import React from "react";
import useMedia from "use-media";
import content from "../../../../content";
import useOnScrollIn from "../../../../hooks/useOnScrollIn";
import cm from "../../../../utils/cm";
import * as classes from "./Quotes.module.scss";

function Quotes() {
  const { quotes } = content;

  return (
    <div className={classes.quotes}>
      <h1>Vad säger kunder?</h1>
      <div className={classes.grid}>
        {quotes.map((v, i) => {
          return (
            <CustomerCard
              quote={v.quote}
              image={v.image}
              name={v.name}
              title={v.title}
              company={v.company}
            />
          );
        })}
      </div>
    </div>
  );
}

type CustomerCardProps = {
  quote: string;
  image: string;
  name: string;
  title: string;
  company: string;
};

/**
 * A card representing a customer
 */
function CustomerCard(props: CustomerCardProps) {
  const isWide = useMedia({ minWidth: 1000 });
  const imgAlt = `${props.name}, ${props.title} på ${props.company}`;
  const [ref, animateIn] = useOnScrollIn(100);

  return (
    <div
      ref={ref}
      className={cm(classes.customer, animateIn && classes.animate)}
    >
      {isWide && <img src={props.image} alt={imgAlt} />}
      <div className={classes.textColumn}>
        <p className={classes.quote}>{props.quote}</p>
        <div className={classes.credentials}>
          {!isWide && <img src={props.image} alt={imgAlt} />}
          <div>
            <p>
              {props.name}, {props.title}
            </p>
            <p>{props.company}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(Quotes);
