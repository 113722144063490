import React, { useEffect } from "react";
import cm from "../../../utils/cm";
import * as classes from "./Grid.module.scss";

type Props = {
  children: React.ReactNode | React.ReactNode[];
} & React.ComponentProps<"article">;

export default function Grid(props: Props) {
  const { children, className, ...compProps } = props;

  return (
    <>
      <article className={cm(classes.grid, className)} {...compProps}>
        {children}
      </article>
    </>
  );
}

export function GridColumn(
  props: {
    span: [number, number];
    children: React.ReactNode | React.ReactNode[];
  } & React.ComponentProps<"section">
) {
  const { span, children, style, className, ...compProps } = props;

  return (
    <section
      style={{
        gridColumnStart: span[0],
        gridColumnEnd: span[1],
        ...(style || {}),
      }}
      className={cm(className, classes.item)}
      {...compProps}
    >
      {children}
    </section>
  );
}
