import React, { useEffect, useMemo, useState } from "react";
import * as classes from "./InfoBox.module.scss";
import Binary from "./binary.inline.svg";

import Grid, { GridColumn } from "../../../../components/layout/Grid/Grid";
import Text from "../../../../components/typography/Text/Text";

import content from "../../../../content";
import cm from "../../../../utils/cm";

type Props = {};

export default function InfoBox(props: Props) {
  const animate = useMemo(
    () => "animate" + (Math.random() + "").replace(".", ""),
    []
  );
  const [animateBinary, setAnimateBinary] = useState(false);

  return (
    <>
      <div className={classes.infoBox}>
        <h1
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-duration="500"
          data-sal-easing="ease"
          className={animate}
        >
          {content.frontInfoBoxHeader}
        </h1>
        <Text
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-duration="500"
          data-sal-easing="ease"
          className={animate}
        >
          {content.frontInfoBoxParagraph}
        </Text>

        <Grid>
          <GridColumn
            data-sal="fade"
            data-sal-delay="100"
            data-sal-duration="500"
            data-sal-easing="ease"
            className={classes.binaryItem}
            span={[1, 3]}
          >
            <Binary className={cm(classes.desktop, classes.animateBinary)} />
          </GridColumn>
          <GridColumn span={[3, 5]}> </GridColumn>
        </Grid>
        <div
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="500"
          data-sal-easing="ease"
        >
          <Binary className={cm(classes.mobile, classes.animateBinary)} />
        </div>
      </div>
    </>
  );
}
